<template>
  <div class="container ma-tb-20">
  	<car-state></car-state>
		<div class="bg-fff setting-index">
			<back-button msg="账户信息"></back-button>
			<div class="setting-info">
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">登录账号：</div>
					<div class="setting-answer ma-l-10">{{detail.LoginId}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">车牌号：</div>
					<div class="setting-answer ma-l-10">{{detail.CarLicense}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">所属分站：</div>
					<div class="setting-answer ma-l-10">{{detail.StationName}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">车辆品牌：</div>
					<div class="setting-answer ma-l-10">{{detail.VehicleBrand}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">上牌时间：</div>
					<div class="setting-answer ma-l-10">{{detail.LicensedTime}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">保险到期时间：</div>
					<div class="setting-answer ma-l-10">{{detail.InsuranceOutTime}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10">
					<div class="setting-title font-bold">限载人数：</div>
					<div class="setting-answer ma-l-10">{{detail.MaxMember}}</div>
				</div>
				<div class="flex f-center c-666 ma-t-10 ma-b-30">
					<div class="setting-title font-bold">车辆状态：</div>
					<div class="setting-answer ma-l-10">{{detail.Status}}</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
let _this;
export default {
  data () {
    return {
			detail: ''
    }
  },
  created(){
    _this = this;
		_this.loadData()
  },
  methods:{
  	// 加载数据
  	async loadData(){
  		let _data = await _this.$request('/Ambulance/v1/Info',{
  			method: 'POST',
  			data: {}
  		});
  		if (_data.Code == 0) {
  			_this.detail = _data.Data;
  		}
  	},
  }
}
</script>

<style lang="less" scoped>
	.title{
		margin-left: 0;
	}
	.setting-index{
		height: auto;
		.setting-info{
			padding-left: 42px;
			font-size: 18px;
			.setting-title{
				width: 18%;
				text-align: right;
			}
		}
	}
</style>